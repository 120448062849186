<template>
	<div v-if="show" class="transaction-completed">
		<Spacer height size="xl" />
		<img class="transaction-completed-img" :src="require('../../assets/icons/success.png')" />
		<h1 class="transaction-completed-title">{{ $t('transfer.transaction-completed') }}</h1>
		<span class="transaction-completed-label">{{ $t('transfer.transaction-completed-description') }}</span>
		<Spacer height size="xl" />
	</div>
</template>

<script>
import Spacer from '@/shared/spacer/Spacer.vue';

export default {
	name: 'TransferSuccess',
	components: {
		Spacer,
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';
.transaction-completed {
	&-img {
		width: 108px;
	}

	&-title {
		font-size: $title-lg;
		margin: 0px;
	}

	&-label {
		font-size: $label-sm;
		color: $light-gray;
		font-size: $label-lg;
	}
}
</style>
