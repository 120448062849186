<template>
	<div class="middle">
		<img v-if="!transfering" class="pointer" :src="require('../../assets/icons/arrow.svg')" />
		<div v-if="transfering" class="loading-dots">
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TransferLoading',
	props: {
		transfering: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';
.loading-dots {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100px;

	@include max-screen($md) {
		flex-direction: column;
		gap: 10px;
	}
}

.dot {
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background-color: #ccc;
	margin: 0 5px;
	opacity: 0.3;
	animation: dot-animation 1s infinite;
}

.dot:nth-child(2) {
	animation-delay: 0.3s;
}

.dot:nth-child(3) {
	animation-delay: 0.6s;
}

@keyframes dot-animation {
	0% {
		opacity: 0.3;
		background-color: green;
	}
	50% {
		opacity: 1;
		background-color: green;
	}
	100% {
		opacity: 0.3;
		background-color: green;
	}
}

.middle {
	margin-top: 15px;
}

.pointer {
	@include max-screen($md) {
		margin-top: 10px;
		-webkit-transform: rotate(90deg);
		-moz-transform: rotate(90deg);
		-o-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
	}
}
</style>
