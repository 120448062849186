<template>
	<div>
		<PageLayout :title="$t('transfer.transfer-between-wallets')" :subtitle="$t('transfer.description')">
			<div class="wallet-container">
				<div class="inner">
					<TransferLoading :show="transfering" />
					<TransferSuccess :show="transactionSuccessful" />
					<div class="top-spacers">
						<Spacer height size="xxl" />
					</div>
					<div class="row">
						<div class="picker">
							<PickerHeader
								:title="$t('transfer.transfer-from')"
								:currency="walletFrom?.currency ?? 'USD'"
								:amount="amount"
								:success="transactionSuccessful"
								type="from"
							/>
							<WalletPicker :wallet="walletFrom" :wallets="wallets.from" :on-wallet-picked="chooseFromWallet" />
						</div>
						<TransactionLoading :transfering="transfering" />
						<div class="picker">
							<PickerHeader
								:title="$t('transfer.transfer-to')"
								:currency="walletTo?.currency ?? 'USD'"
								:amount="amount"
								:success="transactionSuccessful"
								type="to"
							/>
							<WalletPicker :wallet="walletTo" :wallets="wallets.to" :on-wallet-picked="chooseToWallet" />
						</div>
					</div>
					<Spacer height size="xl" />
					<div v-if="!transfering" class="container-amount">
						<div class="grow">
							<span class="label">{{ $t('transfer.enter-amount-to-transfer') }}</span>
							<MaterialInput
								v-model="amount"
								:error="error"
								name="amount"
								type="number"
								:placeholder="$t('transfer.transfer-amount')"
								text-center
								min="0"
								:step="0.01"
							/>
						</div>
					</div>
					<div class="top-spacers">
						<Spacer height size="xl" />
					</div>
					<div v-if="!transactionSuccessful && !transfering">
						<CButton primary @click="tryTransfer"> {{ $t('actions.transfer') }} </CButton>
					</div>
					<div v-if="transactionSuccessful" class="success-buttons">
						<CButton secondary full-width @click="navigateTo('/')"> {{ $t('actions.back-to-dashboard') }} </CButton>
						<CButton primary full-width @click="newTransaction()">
							{{ $t('actions.make-another-transaction') }}
						</CButton>
					</div>
					<Spacer height size="xxl" />
				</div>
			</div>
		</PageLayout>
	</div>
</template>

<script>
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../api';
import Spacer from '@/shared/spacer/Spacer.vue';
import CButton from '@/shared/cbutton/CButton';
import MaterialInput from '@/components/material-input/MaterialInput';
import TransferSuccess from '@/components/transfer/TransferSuccess';
import TransferLoading from '@/components/transfer/TransferLoading';
import TransactionLoading from '@/components/transfer/TransactionLoading';
import WalletPicker from '@/components/transfer/WalletPicker';
import PickerHeader from '@/components/transfer/PickerHeader';
import { extractErrorMessage } from '@/utils/errorHandling';

export default {
	name: 'WalletTransfer',
	components: {
		PageLayout,
		Spacer,
		CButton,
		MaterialInput,
		TransferSuccess,
		TransferLoading,
		TransactionLoading,
		WalletPicker,
		PickerHeader,
	},
	data() {
		return {
			fromDropdownShow: false,
			toDropdownShow: false,
			walletFrom: null,
			walletTo: null,
			wallets: {
				loading: false,
				from: null,
				to: null,
			},
			allWallets: [],
			amount: null,
			error: null,
			transfering: false,
			transactionSuccessful: false,
		};
	},
	async beforeMount() {
		await this.getUsersWallets();
	},
	methods: {
		chooseFromWallet(walletId) {
			this.walletFrom = this.wallets.from.find((w) => w.id === walletId);
			if (this.walletFrom.id === this.walletTo.id) {
				this.walletTo = this.allWallets.find((w) => w.id !== this.walletTo.id);
			}
			this.generateWalletsLists();
		},
		chooseToWallet(walletId) {
			this.walletTo = this.wallets.to.find((w) => w.id === walletId);
			if (this.walletFrom.id === this.walletTo.id) {
				this.walletFrom = this.allWallets.find((w) => w.id !== this.walletTo.id);
			}
			this.generateWalletsLists();
		},
		async getUsersWallets() {
			try {
				this.wallets.loading = true;
				const { wallets } = await apiClient.fetchWalletsWithBalances();
				if (wallets.filter((w) => !w.isDemo).length < 2) {
					this.$router.replace('/');
				}
				this.allWallets = wallets.filter((w) => !w.isDemo);

				if (this.walletFrom === null) {
					this.walletFrom = wallets.find((w) => w.primary);
				} else {
					this.walletFrom = wallets.find((w) => w.id === this.walletFrom.id);
				}

				if (this.walletTo === null) {
					this.walletTo = wallets.find((w) => !w.primary);
				} else {
					this.walletTo = wallets.find((w) => w.id === this.walletTo.id);
				}

				this.generateWalletsLists();
			} finally {
				this.wallets.loading = false;
			}
		},
		generateWalletsLists() {
			this.wallets.from = this.allWallets.filter((w) => !w.isDemo && w.id !== this.walletFrom.id);
			this.wallets.to = this.allWallets.filter((w) => !w.isDemo && w.id !== this.walletTo.id);
		},
		navigateTo(link) {
			if (link.startsWith('/')) {
				this.$router.push({
					path: link,
				});
			} else {
				window.location.href = link;
			}
		},
		newTransaction() {
			this.transactionSuccessful = false;
			this.amount = null;
		},
		async tryTransfer() {
			try {
				this.transfering = true;
				await apiClient.createWalletTransfer(this.walletFrom.id, this.walletTo.id, Number(this.amount));
				this.transactionSuccessful = true;
				this.error = null;
				this.getUsersWallets();
			} catch (e) {
				this.error = extractErrorMessage(e);
			} finally {
				this.transfering = false;
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.wallet-container {
	@include card;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@include max-screen($md) {
		padding: $spacer-m;
	}
}

.inner {
	width: 80%;

	@include max-screen($md) {
		width: 100%;
		padding: $spacer-xs;
	}
}

.row {
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include max-screen($md) {
		flex-direction: column;
	}
}

.picker {
	position: relative;
	text-align: left;
	min-width: 40%;

	@include max-screen($md) {
		width: 100%;
	}
}

.label {
	font-size: $label-sm;
	color: #ffffff;
	opacity: 0.5;
	text-align: left;
}

.container-amount {
	display: flex;
	justify-content: center;
	align-items: center;
}

.grow {
	width: 80%;
}

.success-buttons {
	display: flex;
	gap: $spacer-s;

	@include max-screen($md) {
		flex-direction: column;
	}
}

.top-spacers {
	@include max-screen($md) {
		display: none;
	}
}
</style>
