<template>
	<div class="picker-header">
		<span class="label">{{ title }}</span>
		<div v-if="success">
			<svg :class="type === 'to' && 'rotated-up'" width="10" height="6" viewBox="0 0 10 6" fill="none">
				<path d="M-2.62268e-07 0L5 6L10 5.1656e-07" :fill="type === 'from' ? '#F60057' : '#70C157'" />
			</svg>
			<Spacer width size="s" />
			<span v-if="currency" :class="type === 'from' ? 'currency-red' : 'currency-green'">
				{{ Intl.NumberFormat($i18n.locale, { style: 'currency', currency: currency }).format(amount) }}
			</span>
			<Spacer width size="s" />
		</div>
	</div>
</template>

<script>
import Spacer from '@/shared/spacer/Spacer.vue';

export default {
	name: 'PickerHeader',
	components: {
		Spacer,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		currency: {
			type: String,
			default: 'USD',
		},
		amount: {
			type: Number,
			default: 0,
		},
		success: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: 'from',
		},
	},
};
</script>

<style scoped lang="scss">
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.picker-header {
	display: flex;
	justify-content: space-between;
	margin-bottom: $spacer-xs;

	& > div {
		display: flex;
		align-items: center;
	}
}

.name {
	font-size: 14px;
	color: #ffffff;
}

.label {
	font-size: $label-sm;
	color: #ffffff;
	opacity: 0.5;
	text-align: left;
}

.currency-red {
	color: $currency-red;
	font-size: $label-sm;
}

.currency-green {
	color: $mantis-green;
	font-size: $label-sm;
}

.rotated-up {
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}
</style>
