<template>
	<div v-if="show" class="transaction-loading">
		<Spacer height size="xl" />
		<img class="transaction-loading-img rotate" :src="require('../../assets/icons/loader.svg')" />
		<Spacer height size="xl" />
		<h1 class="transaction-loading-title">{{ $t('transfer.transaction-in-progress') }}</h1>
		<span class="transaction-loading-label">{{ $t('transfer.wait') }}</span>
		<Spacer height size="xl" />
	</div>
</template>

<script>
import Spacer from '@/shared/spacer/Spacer.vue';

export default {
	name: 'TransferLoading',
	components: {
		Spacer,
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';
.transaction-loading {
	&-img {
		width: 108px;
	}

	&-title {
		font-size: $title-lg;
		margin: 0px;
	}

	&-label {
		font-size: $label-sm;
		color: $light-gray;
		font-size: $label-lg;
	}
}

.rotate {
	animation: rotation 2s infinite linear;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
</style>
